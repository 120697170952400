import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'utils/axios';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { LoadingButton } from '@mui/lab';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const SupervisorReview = () => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    function createData(name, data) {
        return {
            name,
            dates: Object.keys(data).map((date) => ({ date, value: data[date] }))
        };
    }

    const updateConfirmedSessions = (providerName, date, sessionId) => {
        const row = rows.filter((r) => r.name === providerName)[0];
        const dateR = row.dates.filter((d) => d.date === date)[0];
        const session = dateR.value.filter((s) => s.id === sessionId)[0];
        session.supervisorConfirmed = true;
        dateR.value = dateR.value.filter((s) => s.id !== sessionId);
        row.dates.filter((d) => d.date === date)[0].value = dateR.value;
        row.dates = row.dates.filter((r) => r.value.length > 0);
        setRows(rows);
    };

    const waitForElementAndScroll = (id) => {
        const element = document.getElementById(id);
        if (element === null) {
            setTimeout(() => {
                waitForElementAndScroll(id);
            }, 100);
        } else {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                localStorage.removeItem('selectedSessionId');
                localStorage.removeItem('providerName');
            }, 1500);
        }
    };

    const getUnconfirmedSessions = () => {
        setIsLoading(true);
        axios.get('/Session/unconfirmed').then((result) => {
            console.log(result.data);
            const rows = [];
            Object.keys(result.data).forEach((providerName) => {
                rows.push(createData(providerName, result.data[providerName]));
            });
            setRows(rows);
            const selectedSessionId = localStorage.getItem('selectedSessionId');
            setIsLoading(false);
            if (selectedSessionId !== null) {
                waitForElementAndScroll(selectedSessionId);
            }
        });
    };

    useEffect(() => {
        getUnconfirmedSessions();
    }, []);

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        useEffect(() => {
            const providerName = localStorage.getItem('providerName');
            if (providerName && row.name === providerName) {
                setOpen(true);
            }
        }, [row]);

        const generateAvatarProps = (name) => {
            const materialColors = [
                '#F44336', // Red
                '#E91E63', // Pink
                '#9C27B0', // Purple
                '#673AB7', // Deep Purple
                '#3F51B5', // Indigo
                '#2196F3', // Blue
                '#03A9F4', // Light Blue
                '#00BCD4', // Cyan
                '#009688', // Teal
                '#4CAF50', // Green
                '#8BC34A', // Light Green
                '#CDDC39', // Lime
                '#FFC107', // Amber
                '#FF9800', // Orange
                '#FF5722', // Deep Orange
                '#607D8B' // Blue Grey
            ];

            // Generate initials
            const nameParts = name.split(' ');
            const firstNameInitial = nameParts[0]?.charAt(0)?.toUpperCase() || '';
            const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0)?.toUpperCase() || '' : '';
            const initials = firstNameInitial + lastNameInitial;

            // Calculate the hash value using the "djb2" hash function
            let hash = 5381;
            for (let i = 0; i < name.length; i += 1) {
                const charCode = name.charCodeAt(i);
                // eslint-disable-next-line no-bitwise
                hash = (hash * 33) ^ charCode;
            }

            // Select a color from the predefined material colors array based on the hash value
            const color = materialColors[Math.abs(hash) % materialColors.length];

            return { initials, color };
        };

        return (
            <>
                <TableRow
                    sx={{
                        backgroundColor: open ? 'inherit' : 'inherit',
                        '& > *': { borderBottom: 'unset' }
                    }}
                >
                    <TableCell colSpan={1}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" colSpan={2} sx={{ paddingLeft: 0 }}>
                        <Typography>
                            <Badge
                                badgeContent={row.dates.reduce((total, { value: subRowValue }) => total + subRowValue.length, 0)}
                                color="primary"
                            >
                                {(() => {
                                    const { initials, color } = generateAvatarProps(row.name);
                                    return <Avatar style={{ backgroundColor: color, color: '#fff' }}>{initials}</Avatar>;
                                })()}
                            </Badge>
                            <span style={{ marginLeft: '20px' }}>{row.name}</span>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small">
                                    <TableBody>
                                        {row.dates.map(({ date: subRow, value: subRowValue }, index) => (
                                            <SubRow
                                                key={subRow}
                                                date={subRow}
                                                subRow={subRowValue}
                                                isOdd={index % 2 === 0}
                                                providerName={row.name}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    function SubRow({ subRow, date, isOdd, providerName }) {
        const [open, setOpen] = useState(false);
        useEffect(() => {
            const selectedSessionId = localStorage.getItem('selectedSessionId');
            if (selectedSessionId && subRow.some((session) => session.id === selectedSessionId)) {
                setOpen(true);
            }
        }, [subRow]);
        subRow = subRow.filter((s) => !s.supervisorConfirmed);
        return (
            <>
                <TableRow
                    key={date}
                    sx={{
                        backgroundColor: (() => {
                            if (open) {
                                return '#e3f2fd';
                            }
                            return isOdd ? '#fbfbfb' : 'inherit';
                        })()
                    }}
                >
                    <TableCell style={{ width: 50, border: 0 }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell sx={{ border: 0 }} style={{ width: 250, height: 60 }} component="th" scope="row">
                        <Typography>{moment.utc(date).format('MMMM Do, YYYY')}</Typography>
                        {!open && (
                            <Typography sx={{ color: '#acacac', fontSize: '11px' }}>
                                {subRow.length} unconfirmed session{subRow.length > 1 ? 's' : ''}
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                    <TableCell sx={{ border: 0 }} />
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <SessionTable sessions={subRow} date={date} providerName={providerName} />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    function SessionTable({ sessions, date, providerName }) {
        const [isConfirmingSession, setIsConfirmingSession] = useState(false);
        const [confirmedSession, setConfirmedSession] = useState(false);
        const navigate = useNavigate();

        const confirmSession = async (sessionId) => {
            setIsConfirmingSession((prevState) => ({ ...prevState, [sessionId]: true }));
            try {
                const response = await axios.post('/Session/confirm', null, {
                    params: {
                        sessionId
                    }
                });

                if (response.data) {
                    setConfirmedSession((prevState) => ({ ...prevState, [sessionId]: true }));
                    updateConfirmedSessions(providerName, date, sessionId);

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Session confirmed!',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                }
            } catch (error) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error confirming session!',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                console.error('Error confirming session:', error);
            }
            setIsConfirmingSession((prevState) => ({ ...prevState, [sessionId]: false }));
        };

        const getBorderColor = (session) => {
            const hasAllData =
                (session.instructionalMode === 'Instructional' &&
                    session.lessonPlan != null &&
                    session.attendanceTaken &&
                    session.hasScores &&
                    session.prepTimeCompleted) ||
                (session.instructionalMode === 'Tutoring' &&
                    session.attendanceTaken &&
                    session.hasNotes &&
                    session.hasFollowUp &&
                    session.hasScores) ||
                session.teacherAbsent === 1;

            if (confirmedSession[session.id]) {
                return 'success';
            }
            if (!hasAllData) {
                session.missingData = true;
                return 'error';
            }
            return 'warning';
        };

        return (
            <Table size="small" aria-label="sessions" sx={{ marginLeft: '40px' }}>
                <TableHead className="leftAlignTableHeaders">
                    <TableRow>
                        <TableCell className="centerTableCells" />
                        <TableCell className="centerTableCells">Group</TableCell>
                        <TableCell className="centerTableCells">Start Time</TableCell>
                        <TableCell className="centerTableCells">End Time</TableCell>
                        <TableCell className="centerTableCells">Duration</TableCell>
                        <TableCell className="centerTableCells">Mode</TableCell>
                        <TableCell className="centerTableCells">Lesson Plan</TableCell>
                        <TableCell className="centerTableCells">Attendance</TableCell>
                        <TableCell className="centerTableCells">Performance</TableCell>
                        <TableCell className="centerTableCells">Prep Time Completed</TableCell>
                        <TableCell className="centerTableCells">Teacher Absent</TableCell>
                        <TableCell className="centerTableCells">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessions.map((session) =>
                        !session.supervisorConfirmed ? (
                            <TableRow key={session.id} id={session.id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fbfbfb' } }}>
                                <TableCell className="leftAlignTableCells">
                                    <Badge color={getBorderColor(session)} badgeContent=" " variant="dot" />
                                </TableCell>
                                <TableCell className="leftAlignTableCells">{session.name}</TableCell>
                                <TableCell className="leftAlignTableCells">{moment(session.start).format('h:mm A')}</TableCell>
                                <TableCell className="leftAlignTableCells">{moment(session.end).format('h:mm A')}</TableCell>
                                <TableCell className="leftAlignTableCells">
                                    {`${
                                        moment(session.end).diff(moment(session.start), 'minutes') >= 60
                                            ? `${Math.floor(moment(session.end).diff(moment(session.start), 'minutes') / 60)}h `
                                            : ''
                                    }${
                                        moment(session.end).diff(moment(session.start), 'minutes') % 60 !== 0
                                            ? `${moment(session.end).diff(moment(session.start), 'minutes') % 60}m`
                                            : ''
                                    }`}
                                </TableCell>
                                <TableCell className="leftAlignTableCells">{session.instructionalMode}</TableCell>
                                {/* <TableCell>
                                {session.teacherAttended ? (
                                    <PersonIcon style={{ color: 'green' }} />
                                ) : (
                                    <PersonOffIcon style={{ color: 'red' }} />
                                )}
                            </TableCell> */}

                                <TableCell
                                    className={`${
                                        session.instructionalMode === 'Instructional' && !session.lessonPlan.name
                                            ? 'centerTableCells'
                                            : 'leftAlignTableCells'
                                    }`}
                                >
                                    {(() => {
                                        if (session.instructionalMode === 'Instructional') {
                                            return session.lessonPlan.name ? (
                                                session.lessonPlan.name
                                            ) : (
                                                <CancelOutlinedIcon style={{ color: 'red' }} />
                                            );
                                        }
                                        return 'N/A';
                                    })()}
                                </TableCell>
                                <TableCell className="centerTableCells">
                                    {session.attendanceTaken ? (
                                        <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                                    ) : (
                                        <CancelOutlinedIcon style={{ color: 'red' }} />
                                    )}
                                </TableCell>
                                <TableCell className="centerTableCells">
                                    {session.hasScores ? (
                                        <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                                    ) : (
                                        <CancelOutlinedIcon style={{ color: 'red' }} />
                                    )}
                                </TableCell>
                                <TableCell className="centerTableCells">
                                    {session.prepTimeCompleted && <CheckCircleOutlinedIcon style={{ color: 'green' }} />}
                                    {!session.prepTimeCompleted && session.instructionalMode === 'Tutoring' && 'N/A'}
                                    {!session.prepTimeCompleted && session.instructionalMode !== 'Tutoring' && (
                                        <CancelOutlinedIcon style={{ color: 'red' }} />
                                    )}
                                </TableCell>

                                <TableCell className="centerTableCells">
                                    <Checkbox checked={session.teacherAbsent} disabled />
                                </TableCell>
                                <TableCell className="centerTableCells">
                                    <Box sx={{ display: 'flex' }}>
                                        <LoadingButton
                                            sx={{ borderRadius: '50px', boxShadow: 'none' }}
                                            variant="contained"
                                            loading={isConfirmingSession[session.id]}
                                            loadingPosition="start"
                                            onClick={() => confirmSession(session.id)}
                                            disabled={confirmedSession[session.id] || session.missingData}
                                        >
                                            Confirm{confirmedSession[session.id] ? 'ed' : ''}
                                        </LoadingButton>

                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                localStorage.setItem('selectedSessionId', session.id);
                                                localStorage.setItem('providerName', providerName);
                                                navigate(`/session/${session.id}`, {
                                                    state: { session, callbackUrl: '/supervisor-review' }
                                                });
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            ''
                        )
                    )}
                </TableBody>
            </Table>
        );
    }

    Row.propTypes = {
        row: PropTypes.shape({
            dates: PropTypes.arrayOf(
                PropTypes.shape({
                    date: PropTypes.string.isRequired
                })
            ).isRequired
        }).isRequired
    };

    return (
        <TableContainer component={Paper} sx={{ maxWidth: '1400px' }}>
            {isLoading ? (
                // Render the CircularProgress loader when data is being loaded
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                // Render the table when data has been loaded
                <Table aria-label="collapsible table" stripe="odd">
                    <TableHead>
                        <TableRow>
                            <TableCell width={20} />
                            <TableCell>Teacher</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
};

export default SupervisorReview;
