import PropTypes from 'prop-types';
import { cloneElement, useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, IconButton, Typography, useScrollTrigger } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import ReactToPrint from 'react-to-print';

// assets
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';

const avatarImage = require.context('assets/images/users', true);

// sticky details card

function ElevationScroll({ children, window }) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 10000,
        target: window || undefined
    });

    return cloneElement(children, {
        style: {
            position: trigger ? 'fixed' : 'relative',
            top: trigger ? 83 : 0,
            width: trigger ? 318 : '100%'
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

// ==============================|| CONTACT CARD/LIST USER DETAILS ||============================== //

const UserDetails = ({ user, onClose, onEditClick, ...others }) => {
    const theme = useTheme();
    console.log(user);
    const avatarProfile = user.avatar && avatarImage(`./${user.avatar}`).default;
    const printableRef = useRef();

    const subjects = [
        { label: 'Reading', id: 1 },
        { label: 'Math', id: 2 }
    ];

    console.log(subjects.find((element) => element.id === user.subject).label);

    return (
        <ElevationScroll {...others}>
            <SubCard
                ref={printableRef}
                sx={{
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                    width: '100%',
                    maxWidth: 342
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-space-between">
                            <Grid item xs zeroMinWidth>
                                <Typography variant="h3" color="primary" style={{ marginTop: '10px' }}>
                                    {user.name}
                                </Typography>
                            </Grid>
                            <Grid item zeroMinWidth className="print-hide">
                                <ReactToPrint
                                    trigger={() => (
                                        <IconButton size="large">
                                            <PrintTwoToneIcon />
                                        </IconButton>
                                    )}
                                    content={() => printableRef.current}
                                    pageStyle="@media print { body { margin: 20mm; } }"
                                />
                            </Grid>

                            <Grid item zeroMinWidth className="print-hide">
                                <IconButton onClick={onClose} size="large">
                                    <HighlightOffTwoToneIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs zeroMinWidth>
                                <Typography color="secondary" component="span">
                                    Subject
                                </Typography>
                                <Typography variant="body2">{subjects.find((x) => x.id === user.subject).label}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs zeroMinWidth>
                                <Typography color="secondary" component="span">
                                    Teaching standard
                                </Typography>
                                <Typography variant="body2">{user.standard.standardId}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs zeroMinWidth>
                                <Typography color="secondary" component="span">
                                    Objective
                                </Typography>
                                <Typography variant="body2">{user.objective}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs zeroMinWidth>
                                <Typography color="secondary" component="span">
                                    Independent Practice
                                </Typography>
                                <Typography variant="body2">{user.independentPractice}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs zeroMinWidth>
                                <Typography color="secondary">Instructions</Typography>
                                <Typography variant="body2">{user.instructions}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs zeroMinWidth>
                                <Typography color="secondary">Materials</Typography>
                                <Typography variant="body2">{user.materials}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="print-hide">
                        <Grid container justifyContent="flex-center">
                            <Button variant="outlined" fullWidth startIcon={<ChatBubbleTwoToneIcon />} onClick={onEditClick}>
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </SubCard>
        </ElevationScroll>
    );
};

UserDetails.propTypes = {
    user: PropTypes.object,
    onClose: PropTypes.func,
    onEditClick: PropTypes.func
};

export default UserDetails;
