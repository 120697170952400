import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    useScrollTrigger
} from '@mui/material';
import { styled, lighten, darken } from '@mui/system';
import * as yup from 'yup';
import { useFormik } from 'formik';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import axios from 'utils/axios';
import grades from './grades';
// sticky edit card

function ElevationScroll({ children, window }) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 10000,
        target: window || undefined
    });

    return React.cloneElement(children, {
        style: {
            position: trigger ? 'fixed' : 'relative',
            top: trigger ? 83 : 0,
            width: trigger ? 318 : '100%'
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

// ==============================|| CONTACT CARD/LIST USER EDIT ||============================== //

const UserEdit = ({ plan, onCancel, onSave, ...others }) => {
    const theme = useTheme();

    // save user to local state to update details and submit letter
    const [lessonPlan, setLessonPlan] = useState(plan);
    useEffect(() => {
        setLessonPlan(plan);
    }, [plan]);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    const validationSchema = yup.object({
        name: yup.string().required('Name is required'),
        subject: yup.number().required('Subject is required'),
        standard: yup.object().required('standard is required'),
        objective: yup.string().required('Objective is required'),
        independentPractice: yup.string().required('Independent Practice is required'),
        instructions: yup.string().required('Instructions is required'),
        materials: yup.string().required('Materials is required'),
        vocabulary: yup.string().required('Vocabulary is required')
    });

    const formik = useFormik({
        initialValues: {
            name: lessonPlan.name,
            subject: lessonPlan.subject,
            standard: lessonPlan.standard,
            objective: lessonPlan.objective,
            independentPractice: lessonPlan.independentPractice,
            instructions: lessonPlan.instructions,
            materials: lessonPlan.materials,
            vocabulary: lessonPlan.vocabulary
        },
        validationSchema,
        onSubmit: (values) => {
            onSave({ ...lessonPlan, ...values });
        }
    });

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const { data } = await axios.get(`/lessonlibrary/standards?subject=${formik.values.subject}`); // For demo purposes.

            if (active) {
                setOptions(data);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.primary.main,
        backgroundColor:
            theme.palette.mode === 'light' ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.8)
    }));

    const GroupItems = styled('ul')({
        padding: 0
    });

    return (
        <ElevationScroll {...others}>
            <form onSubmit={formik.handleSubmit}>
                <SubCard
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                        width: '100%',
                        maxWidth: 342
                    }}
                    content={false}
                >
                    <PerfectScrollbar style={{ height: 'calc(100vh - 83px)', overflowX: 'hidden' }}>
                        <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Name</InputLabel>
                                    <OutlinedInput
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.name && formik.errors.name)}
                                        type="text"
                                        label="Name"
                                        id="name"
                                        name="name"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Subject</InputLabel>
                                    <Select
                                        value={formik.values.subject}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.subject && formik.errors.subject)}
                                        label="Subject"
                                        id="subject"
                                        name="subject"
                                    >
                                        <MenuItem value={1}>Reading</MenuItem>
                                        <MenuItem value={2}>Math</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        open={open}
                                        disabled={!formik.values.subject}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        value={formik.values.standard}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue('standard', newValue);
                                        }}
                                        groupBy={(option) => option.grade}
                                        isOptionEqualToValue={(option, value) => option.standardId === value.standardId}
                                        getOptionLabel={(option) => option.standardId}
                                        options={[...options.filter((o) => o.subject === formik.values.subject)].sort(
                                            (a, b) => grades[a.grade] - grades[b.grade]
                                        )}
                                        filterOptions={(options, state) =>
                                            options.filter(
                                                (o) =>
                                                    o.standardId.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                                                    o.standardsLanguage.toLowerCase().includes(state.inputValue.toLowerCase())
                                            )
                                        }
                                        loading={loading}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Standard"
                                                id="standard"
                                                name="standard"
                                                error={Boolean(formik.touched.standard && formik.errors.standard)}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    )
                                                }}
                                            />
                                        )}
                                        renderGroup={(params) => (
                                            <li>
                                                <GroupHeader>{`${params.group}`}</GroupHeader>
                                                <GroupItems>{params.children}</GroupItems>
                                            </li>
                                        )}
                                        renderOption={(props, option, state) => (
                                            <div {...props} style={{ display: 'inline-block' }}>
                                                <h4>{option.standardId}</h4>
                                                <p>{option.standardsLanguage}</p>
                                            </div>
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Teaching Objective</InputLabel>
                                    <OutlinedInput
                                        value={formik.values.objective}
                                        onChange={formik.handleChange}
                                        type="text"
                                        id="objective"
                                        name="objective"
                                        label="Teaching Objective"
                                        error={Boolean(formik.touched.objective && formik.errors.objective)}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Teaching Instructions</InputLabel>
                                    <OutlinedInput
                                        value={formik.values.instructions}
                                        onChange={formik.handleChange}
                                        type="text"
                                        id="instructions"
                                        name="instructions"
                                        label="Teaching Instructions"
                                        error={Boolean(formik.touched.instructions && formik.errors.instructions)}
                                        multiline
                                        rows={5}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Independent Practice</InputLabel>
                                    <OutlinedInput
                                        value={formik.values.independentPractice}
                                        onChange={formik.handleChange}
                                        type="text"
                                        label="Independent Practice"
                                        id="independentPractice"
                                        name="independentPractice"
                                        error={Boolean(formik.touched.independentPractice && formik.errors.independentPractice)}
                                        multiline
                                        rows={3}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Materials</InputLabel>
                                    <OutlinedInput
                                        value={formik.values.materials}
                                        onChange={formik.handleChange}
                                        type="text"
                                        id="materials"
                                        name="materials"
                                        label="Materials"
                                        error={Boolean(formik.touched.materials && formik.errors.materials)}
                                        multiline
                                        rows={3}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Vocabulary</InputLabel>
                                    <OutlinedInput
                                        value={formik.values.vocabulary}
                                        onChange={formik.handleChange}
                                        type="text"
                                        id="vocabulary"
                                        name="vocabulary"
                                        label="Vocabulary"
                                        error={Boolean(formik.touched.vocabulary && formik.errors.vocabulary)}
                                        multiline
                                        rows={3}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button variant="contained" fullWidth type="submit">
                                            Save
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="outlined" fullWidth onClick={() => onCancel(lessonPlan)}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PerfectScrollbar>
                </SubCard>
            </form>
        </ElevationScroll>
    );
};

UserEdit.propTypes = {
    user: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func
};

export default UserEdit;
