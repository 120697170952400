const grades = {
    Kindergarden: 1,
    '1st': 2,
    '2nd': 3,
    '3rd': 4,
    '4th': 5,
    '5th': 6,
    '6th': 7,
    '7th': 8,
    '8th': 9,
    '9th': 10,
    '10th': 11,
    '9th-10th': 12,
    '11th': 13,
    '12th': 14,
    '11th-12th': 15
};

export default grades;
