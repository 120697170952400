import { useEffect, useState, Fragment } from 'react';

// material-ui
import { Button, Grid, InputAdornment, OutlinedInput, Typography } from '@mui/material';

// third-party
import { isEmpty } from 'lodash';

// project imports
import UserDetails from '../UserDetails';
import UserEdit from '../UserEdit';
import ContactList from 'ui-component/cards/ContactList';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

import { useDispatch, useSelector } from 'store';
import { getLessonPlans, createLessonPlan, modifyLessonPlan } from 'store/slices/contact';

// assets
import { IconSearch } from '@tabler/icons';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// ==============================|| CONTACT LIST ||============================== //

const CardListPage = () => {
    const dispatch = useDispatch();
    const [lessonPlan, setLessonPlan] = useState({});
    const [data, setData] = useState([]);

    // get all users details
    const { lessonPlans } = useSelector((state) => state.lessonPlan);

    useEffect(() => {
        setData(lessonPlans);
        if (!isEmpty(lessonPlan)) {
            const idx = lessonPlans.findIndex((item) => item.id === lessonPlan.id);
            if (idx > -1) setLessonPlan(lessonPlans[idx]);
        }
    }, [lessonPlans, lessonPlan]);

    useEffect(() => {
        dispatch(getLessonPlans());
    }, []);

    // edit selected user and modify users data
    const modifyUser = async (u) => {
        if (u.id === '') {
            await dispatch(createLessonPlan(u));
        } else {
            await dispatch(modifyLessonPlan(u));
        }
    };

    // handle new user insert action
    const [userDetails, setUserDetails] = useState(false);
    const [userEdit, setUserEdit] = useState(false);
    const handleOnAdd = () => {
        setLessonPlan({
            id: '',
            name: '',
            subject: '',
            standard: null,
            objective: '',
            independentPractice: '',
            instructions: '',
            materials: '',
            vocabulary: ''
        });
        setUserDetails(false);
        setUserEdit(true);
    };

    const handleSearchTermChange = (searchTerm) => {
        const filteredLessonPlans = [...lessonPlans.filter((x) => x.name.includes(searchTerm))];
        setData(filteredLessonPlans);
    };

    return (
        <MainCard title="Lesson Library">
            <Grid container spacing={gridSpacing}>
                <Grid item xs zeroMinWidth sx={{ display: userDetails || userEdit ? { xs: 'none', md: 'block' } : 'block' }}>
                    <Grid container alignItems="center" spacing={gridSpacing}>
                        <Grid item xs zeroMinWidth>
                            <OutlinedInput
                                id="input-search-card-style1"
                                placeholder="Search Lesson"
                                onChange={(e) => handleSearchTermChange(e.target.value)}
                                fullWidth
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="16px" />
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                sx={{ px: 2.75, py: 1.5 }}
                                onClick={handleOnAdd}
                            >
                                Add
                            </Button>
                        </Grid>

                        {data.map((item) => (
                            <Fragment key={item.id}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={0}>
                                        <Grid item xs={12}>
                                            <ContactList
                                                name={item.name}
                                                subject={item.subject}
                                                onActive={() => {
                                                    setLessonPlan(item);
                                                    setUserDetails(true);
                                                    setUserEdit(false);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                </Grid>
                {userDetails && (
                    <Grid item sx={{ width: 342, margin: { xs: '0 auto', md: 'initial' } }}>
                        <UserDetails
                            user={lessonPlan}
                            onEditClick={() => {
                                setUserDetails(false);
                                setUserEdit(true);
                            }}
                            onClose={() => {
                                setUserDetails(false);
                                setUserEdit(false);
                            }}
                        />
                    </Grid>
                )}

                {userEdit && (
                    <Grid item sx={{ width: 342, margin: { xs: '0 auto', md: 'initial' } }}>
                        <UserEdit
                            plan={lessonPlan}
                            onSave={(u) => {
                                if (u.id) setUserDetails(true);
                                setUserEdit(false);
                                modifyUser(u);
                            }}
                            onCancel={(u) => {
                                if (u.id) setUserDetails(true);
                                setUserEdit(false);
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </MainCard>
    );
};

export default CardListPage;
