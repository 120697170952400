const skillLevels = {
    reading: [
        { title: 'Phonemic awareness' },
        { title: 'Decoding/Phonics' },
        { title: 'Fluency' },
        { title: 'Accuracy' },
        { title: 'Vocabulary' },
        { title: 'Reading comprehension skills' },
        { title: 'Reading Comprehension strategies' },
        { title: 'Listening and speaking skills' }
    ],
    math: [
        { title: 'Early math concepts' },
        { title: 'Whole number concepts' },
        { title: 'Place value' },
        { title: 'Operations and algebraic thinking' },
        { title: 'Problem solving' },
        { title: 'Fractions' },
        { title: 'Decimals' },
        { title: 'Time' },
        { title: 'Money' },
        { title: 'Geometry' }
    ]
};

export default skillLevels;
