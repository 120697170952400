import { Stack, FormControlLabel, Autocomplete, Switch, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { showErrorAlert } from 'utils/global-functions';
import { useDispatch } from 'store';

const TeacherAbsent = Object.freeze({
    Yes: 'yes',
    No: 'no'
});

export default function TeacherAbsentSection({
    substituteTeachers,
    initialTeacherAbsent,
    teacherAbsent,
    setTeacherAbsent,
    disabled,
    substituteTeacherId,
    attendanceTaken,
    isSavingTeacherAttendance,
    setIsSavingTeacherAttendance,
    sessionId,
    setTab,
    setSession,
    teacherAbsentSaveIsDirty,
    setTeacherAbsentSaveIsDirty
}) {
    const dispatch = useDispatch();

    const [selectedSubstitute, setSelectedSubstitute] = useState(undefined);
    const [showSubstituteRequiredError, setShowSubstituteRequiredError] = useState(false);

    useEffect(() => {
        setSelectedSubstitute(substituteTeachers.find((t) => t.id === substituteTeacherId));
    }, []);

    useEffect(() => {
        setTeacherAbsentSaveIsDirty(
            (!initialTeacherAbsent && teacherAbsent) ||
                (substituteTeacherId ? selectedSubstitute?.id !== substituteTeacherId : selectedSubstitute?.id !== undefined)
        );
    }, [initialTeacherAbsent, teacherAbsent, substituteTeacherId, selectedSubstitute]);

    const updateTeacherAttendance = async (value, substituteTeacherId = null) => {
        setIsSavingTeacherAttendance(true);

        try {
            const params = { sessionId, absent: value };
            if (substituteTeacherId) {
                params.substituteTeacherId = substituteTeacherId;
            }
            const { data } = await axios.post('/Session/SubmitTeacherAttendance', null, { params });

            setTab('plan');
            setTeacherAbsent(data.teacherAbsent === 1);
            setSession(data);
        } catch (err) {
            console.error(err);
            showErrorAlert(dispatch, err);
        }

        setIsSavingTeacherAttendance(false);
    };

    const handleSwitchChanged = () => {
        const teacherAbs = !teacherAbsent;
        setTeacherAbsent(teacherAbs);
        if (!teacherAbs) {
            setSelectedSubstitute(undefined);
            setShowSubstituteRequiredError(false);
            if (teacherAbs !== initialTeacherAbsent) updateTeacherAttendance(TeacherAbsent.No);
        }
    };

    const handleSubstituteChange = (e, selected) => {
        setSelectedSubstitute(selected);
        if (showSubstituteRequiredError) setShowSubstituteRequiredError(false);
    };

    const onSaveClick = () => {
        if (attendanceTaken && !selectedSubstitute?.id) {
            setShowSubstituteRequiredError(true);
            return;
        }
        updateTeacherAttendance(TeacherAbsent.Yes, selectedSubstitute?.id);
    };

    return (
        <Stack direction="row">
            <FormControlLabel
                control={<Switch checked={teacherAbsent} onChange={handleSwitchChanged} disabled={disabled} />}
                label="Teacher Absent"
            />
            {teacherAbsent && (
                <>
                    <Autocomplete
                        sx={{ width: 200, paddingLeft: 0.5 }}
                        options={substituteTeachers ?? []}
                        renderInput={(params) => (
                            <TextField {...params} label="Substitute Teacher" size="small" error={showSubstituteRequiredError} />
                        )}
                        onChange={handleSubstituteChange}
                        value={selectedSubstitute ?? null}
                        disableClearable={attendanceTaken}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                {option.label}
                            </li>
                        )}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        disabled={isSavingTeacherAttendance || !teacherAbsentSaveIsDirty}
                        sx={{ marginLeft: 2.5, minWidth: 0, maxHeight: '36px', padding: '10px' }}
                        onClick={onSaveClick}
                    >
                        Save
                    </Button>
                </>
            )}
        </Stack>
    );
}
