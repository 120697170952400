import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// styles
const ListWrapper = styled('div')(({ theme }) => ({
    padding: '0 0 15px 0',
    borderBottom: theme.palette.mode === 'dark' ? 'none' : '1px solid',
    borderTop: theme.palette.mode === 'dark' ? 'none' : 'none',
    borderColor: `${theme.palette.grey[100]}!important`
}));

const subjects = [
    { label: 'Reading', id: 1 },
    { label: 'Math', id: 2 }
];

// ==============================|| USER CONTACT LIST ||============================== //

const ContactList = ({ name, subject, onActive }) => {
    const theme = useTheme();

    return (
        <ListWrapper>
            <Grid container alignItems="center" spacing={gridSpacing}>
                <Grid item xs={12} sm={6} onClick={() => onActive && onActive()} style={{ cursor: 'pointer' }}>
                    <Grid container alignItems="center" spacing={gridSpacing} sx={{ flexWrap: 'nowrap' }}>
                        <Grid item sm zeroMinWidth>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" component="div">
                                        {name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">{subjects.find((x) => x.id === subject).label}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListWrapper>
    );
};

ContactList.propTypes = {
    name: PropTypes.string,
    onActive: PropTypes.func
};

export default ContactList;
