// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    lessonPlans: []
};

const slice = createSlice({
    name: 'lessonPlan',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getContactsSuccess(state, action) {
            state.lessonPlans = action.payload;
        },

        // MODIFY CONTACT
        modifyContactSuccess(state, action) {
            state.lessonPlans = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLessonPlans() {
    return async () => {
        try {
            const response = await axios.get('/lessonlibrary/list');
            dispatch(slice.actions.getContactsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createLessonPlan(contact) {
    return async () => {
        try {
            const response = await axios.post('/lessonlibrary/create', contact);
            dispatch(slice.actions.modifyContactSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function modifyLessonPlan(contact) {
    return async () => {
        try {
            const response = await axios.put(`/lessonlibrary/update/${contact.id}`, contact);
            dispatch(slice.actions.modifyContactSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
