import React, { useState } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
    Paper,
    Box,
    Checkbox,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    OutlinedInput,
    Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const GradeStudentsForm = ({ session, onSubmit }) => {
    const [students, setStudents] = useState([
        ...session.students
            .filter((s) => s.attendance?.attendance === 2)
            .map((x) => {
                if (x.score === null) {
                    x.scoreValue = undefined;
                    x.scoreNote = '';
                } else {
                    x.scoreValue = x.score.scoreValue;
                    x.scoreNote = x.score.scoreNote;
                }
                return x;
            })
    ]);

    const [isSavingGrades, setIsSavingGrades] = useState(false);
    const [scoresChanged, setScoresChanged] = useState(false);

    const check = (id, value) => {
        const studentsCopy = students.map((s) => (s.studentId === id ? { ...s, scoreValue: value } : s));
        setStudents(studentsCopy);
        setScoresChanged(true);
    };

    const setNote = (id, value) => {
        const studentsCopy = students.map((s) => (s.studentId === id ? { ...s, scoreNote: value } : s));
        setStudents(studentsCopy);
        setScoresChanged(true);
    };

    const getStudentScores = () =>
        students.map((s) => ({
            attendanceId: s.attendance?.attendanceId,
            scoreId: s.score == null ? '' : s.score.scoreId,
            score: s.scoreValue,
            note: s.scoreNote
        }));

    const handleSave = async () => {
        setIsSavingGrades(true);
        await onSubmit(getStudentScores());
        setIsSavingGrades(false);
    };

    return (
        <div>
            <Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30%' }}>Student</TableCell>
                                <TableCell style={{ width: '10%' }}>Pass</TableCell>
                                <TableCell style={{ width: '10%' }}>Fail</TableCell>
                                <TableCell style={{ width: '50%' }}>Anecdotal Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {students.map((student) => (
                                <TableRow key={student.studentName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell style={{ width: '30%' }} component="th" scope="row" sx={{ verticalAlign: 'top' }}>
                                        {student.studentLastName}, {student.studentFirstName} {student.studentMiddleName}
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} sx={{ verticalAlign: 'top' }}>
                                        <Checkbox
                                            checked={student.scoreValue === 0}
                                            onChange={() => check(student.studentId, 0)}
                                            disabled={session.supervisorConfirmed}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} sx={{ verticalAlign: 'top' }}>
                                        <Checkbox
                                            checked={student.scoreValue === 1}
                                            onChange={() => check(student.studentId, 1)}
                                            disabled={session.supervisorConfirmed}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: '50%' }}>
                                        <OutlinedInput
                                            value={student.scoreNote}
                                            onChange={(e) => setNote(student.studentId, e.target.value)}
                                            fullWidth
                                            type="text"
                                            multiline
                                            rows={1}
                                            disabled={session.supervisorConfirmed}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Divider style={{ marginTop: '30px', width: '100%' }} />
            <Box textAlign="center" sx={{ mt: 2 }}>
                <LoadingButton
                    variant="contained"
                    loading={isSavingGrades}
                    loadingPosition="start"
                    startIcon={<DoneAllIcon />}
                    onClick={handleSave}
                    disabled={!scoresChanged || session.supervisorConfirmed}
                >
                    Submit Scores
                </LoadingButton>
            </Box>
        </div>
    );
};

export default GradeStudentsForm;
