import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken, user) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        localStorage.setItem('id', user.id);
        localStorage.setItem('firstName', user.firstName);
        localStorage.setItem('lastName', user.lastName);
        localStorage.setItem('userName', user.userName);
        localStorage.setItem('isSupervisor', user.isSupervisor);
        localStorage.setItem('roles', user.roles);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('id');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('userName');
        localStorage.removeItem('isSupervisor');
        localStorage.removeItem('roles');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const getFromLS = (propertyName) => window.localStorage.getItem(propertyName);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const user = {
                    id: getFromLS('id'),
                    firstName: getFromLS('firstName'),
                    lastName: getFromLS('lastName'),
                    userName: getFromLS('userName'),
                    isSupervisor: getFromLS('isSupervisor'),
                    roles: getFromLS('roles')
                };
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken, user);
                    // temp commented out
                    // const response = await axios.get('/api/account/me');
                    // const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true
                            // user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const params = new URLSearchParams();
        params.append('client_id', 'Encore365.Title1Portal');
        params.append('grant_type', 'password');
        params.append('username', email);
        params.append('password', password);
        const response = await axios.post('/connect/token', params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        const serviceToken = response.data.access_token;
        const user = response.data.user;
        setSession(serviceToken, user);
        // We need to trigger a reload of the page to show the correct menu items based on provider type. There's definitely a better way to do this.
        window.location.reload();
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    function forgotPassword(email, callback, errorCallback) {
        axios
            .post(`/User/forgotpassword?email=${email}`)
            .then((response) => {
                if (callback) callback(response);
            })
            .catch((error) => {
                if (errorCallback) errorCallback(error);
            });
    }

    function resetPassword(email, token, currentPassword, newPassword, callback, errorCallback) {
        axios
            .post('/User/resetpassword', { email, token, currentPassword, newPassword })
            .then((response) => {
                if (callback) callback(response);
            })
            .catch((error) => {
                if (errorCallback) errorCallback(error);
            });
    }

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, forgotPassword, updateProfile }}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
